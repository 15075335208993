<template>
  <div class="my-promotion">
    <div class="header">
      <div class="name acea-row row-center-wrapper">
        <div class="myCenter" @click="quit">
          <span class="iconfont icontuichu"></span>
        </div>
        <div>当前佣金</div>
      </div>
      <div class="num">{{ Info.commissionCount }}</div>
      <div class="profit acea-row row-between-wrapper">
        <div class="item">
          <div>昨日收益</div>
          <div class="money">{{ Info.lastDayCount }}</div>
        </div>
        <div class="item">
          <div>累积已提</div>
          <div class="money">{{ Info.extractCount }}</div>
        </div>
      </div>
    </div>
    <div class="bnt bg-color-red" @click="toCash">立即提现</div>
    <div class="list acea-row row-between-wrapper">
      <router-link class="item acea-row row-center-wrapper row-column" :to="'/poster'">
        <span class="iconfont iconicon_erweima"></span>
        <div>推广名片</div>
      </router-link>
      <router-link class="item acea-row row-center-wrapper row-column" :to="'/PromoterList'">
        <span class="iconfont icontongji"></span>
        <div>推广人统计</div>
      </router-link>
      <router-link class="item acea-row row-center-wrapper row-column" :to="'/withdrawalRecord'">
        <span class="iconfont iconicon-qiandai"></span>
        <div>提现记录</div>
      </router-link>
      <router-link class="item acea-row row-center-wrapper row-column" :to="'/user/promoter_order'">
        <span class="iconfont iconicon-dingdan"></span>
        <div>推广人订单</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import Generalize from "@api/generalize";
import { Dialog } from 'vant';
export default {
  name: "UserPromotion",
  props: {},
  data: function() {
    return {
      Info: {
        lastDayCount: 0,
        extractCount: 0,
        commissionCount: 0
      }
    };
  },
  mounted: function() {
    this.invitecode();
  },
  methods: {
    quit() {
      Dialog.confirm({
        title: '标题',
        message: '是否退出登录'
      }).then(() => {
        this.$router.replace("tglogin")
        // location.reload();
      }).catch(() => {
        // on cancel
      });
    },
    toCash() {
      this.$router.push({
        path: "/UserCash",
        query: { 'money': this.Info.commissionCount }
      });
    },
    async invitecode() {
      try {
        const res = await Generalize.invite_code();
        this.$set(this.Info, "commissionCount", res.data.current_money);
        this.$set(this.Info, "extractCount", res.data.withdraw_money);
        this.$set(this.Info, "lastDayCount", res.data.yes_money);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
/*我的推广*/
.my-promotion {
  height: 100vh;
}
.my-promotion .header {
  background-image: url("../../../../public/static/images/vip/promotionBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 188px;
}

.my-promotion .header .name {
  font-size: 15px;
  color: #fff;
  padding-top: 29px;
  position: relative;
  text-align: center;
}

.my-promotion .header .name .record {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 138px;
  width: 140px;
}
.myCenter {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;

  .icontuichu {
    font-size: 18px!important;
  }
}
.my-promotion .header .name .record .iconfont {
  font-size: 12px;
  margin-left: 5px;
}

.my-promotion .header .num {
  text-align: center;
  color: #fff;
  margin-top: 12px;
  font-size: 45px;
  font-family: "GuildfordProBook 5";
}

.my-promotion .header .profit {
  padding: 0 10px;
  margin-top: 17px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
}

.my-promotion .header .profit .item {
  min-width: 100px;
  text-align: center;
}

.my-promotion .header .profit .item .money {
  font-size: 17px;
  color: #fff;
}

.my-promotion .bnt {
  font-size: 14px;
  color: #fff;
  width: 129px;
  height: 34px;
  border-radius: 25px;
  text-align: center;
  line-height: 34px;
  margin: -16px auto 0 auto;
  background: linear-gradient(to bottom, #f10c0c 0%, #ec9b08 100%) !important;
}

.my-promotion .list {
  padding: 0 10px;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.my-promotion .list .item {
  width: 172px;
  height: 120px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
  font-size: 15px;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.my-promotion .list .item .iconfont {
  font-size: 35px;
  background-image: linear-gradient(to bottom, #f10c0c 0%, #ec9b08 100%);

  background-image: -webkit-linear-gradient(to right, #fc4d3d 0%, #e93323 100%);

  background-image: -moz-linear-gradient(to right, #fc4d3d 0%, #e93323 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}
</style>
